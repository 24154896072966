<template>
	<div>
		<Nav @link="linkTo" :idx="7"></Nav>
		<div v-for="(item, i) in list" :key="i">
			<div class="title">{{item.title}}</div>
			<div class="line_block"></div>
			<div class="pension_desc">{{item.desc}}</div>
			<div class="list_box">
				<div class="list_slider" v-for="(list, j) in item.sliders" :key="j">
					<img class="icon" :src="list.src" alt="">
					<div class="sub_title">{{list.subTitle}}</div>
					<div class="sub_desc">{{list.subDesc}}</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'

export default {
	name: 'productService2',
	components: {
		Nav,
		Footer
	},
	data () {
		return {
			list: [
				{
					title: '智慧物联',
					desc: '物联网是新一代信息技术的重要组成部分。以物联网、人工智能、智能硬件和数据中台等新型信息技术为载体，服务新一代信息基础设施建设，打造“物联、数联、众联”的一体化信息支撑体系，全面感知物理世界、智能处理感知信息，共建开放共享的生态，提升万物互联与智联能力。面向物联管理和集成应用平台研发，为新一代信息基础设施提供设施物联建设和运营服务。开发物联应用平台，融合数据、打造信息化、网络化和智能化的管理新模式。',
					sliders: []
				},
				{
					title: '智慧居家安防',
					desc: '智能安防系统是智能家居系统的一部分，它与智能家居相结合，区别于传统的安防产品，更人性化、智能化，构筑了家庭立体化的智能安防体系，保障了家庭生命财产的安全。除了大家耳熟能详的智能门锁、监控之外，智能安防系统还涵盖了智能网关、智能插座、人体红外、智能烟雾报警、可燃气体探测、SOS紧急按钮、智能水浸报警器、智能门磁、智能猫眼等一系列智能化设备。智慧安防系统通过云计算、大数据等物联网技术构建立体安防体系，以智能消防、家居安防为核心，为您的家庭构筑安全防线，全方位满足家庭生活中的安全需求。',
					sliders: [
						{
							src: require('@/assets/img/proIcon/pros_1.png'),
							subTitle: '防盗报警功能',
							subDesc: '通过安装智能门/窗磁，24小时监测门窗闭合状态，起到防入侵的功能。若有异常闯入者，平台系统将第一时间收到报警信息，及时与用户取得联系。同时联动智能摄像头，可第一时间拍摄入侵者高清画面，第一时间取证并报警，可将危险和损失降到最低。'
						},
						{
							src: require('@/assets/img/proIcon/pros_2.png'),
							subTitle: '智慧消防',
							subDesc: '可燃气体传感器、水浸传感器、烟雾报警器能实时保护家人的安全。一旦检测到漏水、漏气等现象，报警器会自动报警，系统会及时响应并帮助处理险情。'
						},
						{
							src: require('@/assets/img/proIcon/pros_3.png'),
							subTitle: '紧急求助功能',
							subDesc: '在家遇到紧急突发情况时，能够简单、便捷地求助的终端设备。将紧急按钮安装在客厅、卧室或洗手间，当发生突发事件或遇险时用于求救和警报的作用。'
						},
					]
				},
			]
		}
	},
	created () {},
	mounted () {
		window.scrollTo(0, 0);
	},
	methods: {
		linkTo (routerName) {
			this.$router.push(routerName)
		},
	}
}

</script>
<style lang='less' scoped>
@media screen and (max-width: 767px) {	// 移动端

}

@media screen and (min-width: 768px) and (max-width: 991px) {	// iPad
	
}

@media screen and (min-width: 992px) {	// PC端
	.title {
		margin-top: 80px;
		margin-bottom: 43px;
		text-align: center;
		font-size: 34px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 400;
		color: #1F1836;
	}
	.line_block {
		margin: 0 auto;
		width: 51px;
		height: 2px;
		background: #2890FC;
	}
	.pension_desc {
		width: 1200px;
		letter-spacing: 2px;
		line-height: 1.5;
		margin: 40px auto 40px;
		font-size: 20px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		text-indent: 2em;
	}
	.list_box {
		width: 900px;
		margin: 40px auto 40px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.list_slider {
			width: 225px;
			margin: 10px;
			box-sizing: border-box;
			padding: 25px 12px;
			background: #F8F8F8;
			.icon {
				width: 80px;
				display: block;
				margin: auto;
			}
			.sub_title {
				text-align: center;
				margin-top: 16px;
				font-size: 18px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #1A1A1A;
			}
			.sub_desc {
				letter-spacing: 1px;
				line-height: 1.5;
				margin-top: 25px;
				font-size: 14px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #999999;
				text-align: center;
			}
		}
	}
}
</style>